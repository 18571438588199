@import "~roboto-fontface/css/roboto/roboto-fontface.css";
@import '~material-icons/iconfont/material-icons.css';
@import "~material-design-icons-iconfont/dist/material-design-icons.css";
@import "~@circlon/angular-tree-component/css/angular-tree-component.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Symbols|Material+Symbols+Outlined");
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "mixins";
@import "base";
@import "theming";
